import React from "react"
import { Provider } from "react-redux"
import App from "next/app"
import Head from "next/head"
import withRedux from "next-redux-wrapper"
import { DefaultSeo, LogoJsonLd } from "next-seo"
import ConfigureStore from "../store/ConfigureStore"
import Layout from "../components/Layout"
import CrispStyleContainer from "../components/CrispStyleContainer"
import CssBaseline from "@material-ui/core/CssBaseline"
import { globals as GlobalStyle } from "../styles"
import { ThemeProvider } from "@material-ui/core/styles"
import SEO from "../next-seo.config"
import { setMobileDetect, mobileParser } from "react-responsive-redux"
import theme from "../theme"
import { CDN_DOMAIN } from "../config/cdn"

// import "react-phone-number-input/style.css"

const makeStore = (initialState, options) => {
    return ConfigureStore(initialState)
}

class MyApp extends App {
    static async getInitialProps(appContext) {
        let ctx = appContext.ctx
        // we can dispatch from here too
        //  ctx.store.dispatch({ type: 'FOO', payload: 'foo' })

        if (ctx.req) {
            const mobileDetect = mobileParser(ctx.req)
            ctx.store.dispatch(setMobileDetect(mobileDetect))
        }

        const appProps = await App.getInitialProps(appContext)

        return { ...appProps }
    }

    componentDidMount() {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side')

        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles)
        }
    }

    render() {
        const { Component, pageProps, store, router } = this.props

        return (
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                    <CssBaseline />
                    <GlobalStyle />
                    <CrispStyleContainer router={router} />
                    {/* Pass pageContext to the _document though the renderPage enhancer
                      to render collected styles on server side. */}
                    <DefaultSeo {...SEO} />
                    <LogoJsonLd
                        logo={`${CDN_DOMAIN}/static/logo_green.png`}
                        url="https://unemplacement.com"
                    />
                    <Layout>
                        <Head>
                            <meta
                                name="viewport"
                                content="minimum-scale=1.0, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, width=device-width, shrink-to-fit=no"
                            />
                        </Head>
                        <Component {...pageProps} />
                    </Layout>
                </ThemeProvider>
            </Provider>
        )
    }
}

export default withRedux(makeStore)(MyApp)
